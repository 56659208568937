/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PartnerBaseSchema } from '../models/partner-base-schema';
import { PartnerProductsAsReference } from '../models/partner-products-as-reference';
import { PartnerProductsPupulated } from '../models/partner-products-pupulated';
import { PartnerRequestBody } from '../models/partner-request-body';

@Injectable({
  providedIn: 'root',
})
export class PartnersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation partnersGet
   */
  static readonly PartnersGetPath = '/partners';

  /**
   * Get partners list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnersGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<PartnerBaseSchema & PartnerProductsAsReference>>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.PartnersGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PartnerBaseSchema & PartnerProductsAsReference>>;
      })
    );
  }

  /**
   * Get partners list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partnersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnersGet(params?: {

  }): Observable<Array<PartnerBaseSchema & PartnerProductsAsReference>> {

    return this.partnersGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PartnerBaseSchema & PartnerProductsAsReference>>) => r.body as Array<PartnerBaseSchema & PartnerProductsAsReference>)
    );
  }

  /**
   * Path part for operation partnersImport
   */
  static readonly PartnersImportPath = '/partners/import';

  /**
   * Bulk partner import
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnersImport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  partnersImport$Response(params?: {
    addaddress?: boolean;
      body?: Array<PartnerRequestBody>
  }): Observable<StrictHttpResponse<{ 'message'?: string, 'insertedCount'?: number }>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.PartnersImportPath, 'post');
    if (params) {

      rb.query('addaddress', params.addaddress, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string, 'insertedCount'?: number }>;
      })
    );
  }

  /**
   * Bulk partner import
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partnersImport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  partnersImport(params?: {
    addaddress?: boolean;
      body?: Array<PartnerRequestBody>
  }): Observable<{ 'message'?: string, 'insertedCount'?: number }> {

    return this.partnersImport$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string, 'insertedCount'?: number }>) => r.body as { 'message'?: string, 'insertedCount'?: number })
    );
  }

  /**
   * Path part for operation partnerPost
   */
  static readonly PartnerPostPath = '/partner';

  /**
   * Create a new partner
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnerPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  partnerPost$Response(params?: {
      body?: PartnerRequestBody
  }): Observable<StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.PartnerPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>;
      })
    );
  }

  /**
   * Create a new partner
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partnerPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  partnerPost(params?: {
      body?: PartnerRequestBody
  }): Observable<PartnerBaseSchema & PartnerProductsPupulated> {

    return this.partnerPost$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>) => r.body as PartnerBaseSchema & PartnerProductsPupulated)
    );
  }

  /**
   * Path part for operation partnerGet
   */
  static readonly PartnerGetPath = '/partner/{id}';

  /**
   * Read a partner
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnerGet$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.PartnerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>;
      })
    );
  }

  /**
   * Read a partner
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partnerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnerGet(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<PartnerBaseSchema & PartnerProductsPupulated> {

    return this.partnerGet$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>) => r.body as PartnerBaseSchema & PartnerProductsPupulated)
    );
  }

  /**
   * Path part for operation partnerPut
   */
  static readonly PartnerPutPath = '/partner/{id}';

  /**
   * Modify a partner
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnerPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  partnerPut$Response(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: PartnerRequestBody
  }): Observable<StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.PartnerPutPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>;
      })
    );
  }

  /**
   * Modify a partner
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partnerPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  partnerPut(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: PartnerRequestBody
  }): Observable<PartnerBaseSchema & PartnerProductsPupulated> {

    return this.partnerPut$Response(params).pipe(
      map((r: StrictHttpResponse<PartnerBaseSchema & PartnerProductsPupulated>) => r.body as PartnerBaseSchema & PartnerProductsPupulated)
    );
  }

  /**
   * Path part for operation partnerDelete
   */
  static readonly PartnerDeletePath = '/partner/{id}';

  /**
   * Delete a partner
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnerDelete$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.PartnerDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete a partner
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partnerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnerDelete(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.partnerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

}
