import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PartnerListComponent } from "./components/partner-list/partner-list.component";
import { PartnersComponent } from './partners.component';
import { NotFoundComponent } from 'app/pages/miscellaneous/not-found/not-found.component';
import { PartnerFormComponent } from './components/partner-form/partner-form.component';
import { PartnerImportComponent } from './components/partner-import/partner-import.component';

export const routes: Routes = [{
  path: '',
  component: PartnersComponent,
  children: [
    {
      path: 'list',
      component: PartnerListComponent,
    },
    {
      path: 'edit/:id',
      component: PartnerFormComponent,
    },
    {
      path: 'new',
      component: PartnerFormComponent,
    },
    {
      path: 'import',
      component: PartnerImportComponent,
    },
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PartnersRoutingModule {
}

export const routedComponents = [PartnerListComponent, PartnerFormComponent];
