import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { PartnerFormComponent } from 'app/modules/partners/components/partner-form/partner-form.component';
import { ProductBaseSchema, PartnerProductsPupulated, DocumentSchema } from 'app/openapi-hq/models';
import { PartnersService, ProductsService } from 'app/openapi-hq/services';
import { ApiConfiguration } from 'app/openapi-hq/api-configuration';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PartnerAutocompleteComponent } from '../partner-autocomplete/partner-autocomplete.component';

type ProductSchema = ProductBaseSchema & PartnerProductsPupulated;

@Component({
  selector: 'ntgr-product-form',
  templateUrl: './product-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnDestroy {

  public crudType: 'new' | 'edit';
  private id: string;
  private subscriptions:Subscription[] = [];
  public selectedPartner: {text: string; value: string;};
  public selectedPartners$ = new BehaviorSubject<string[]>([]);
  public staticURL: string;

  defaultValues = {

  }

  @ViewChildren('linksFocus') linksFocus: QueryList<any>;
  @ViewChildren('partnersFocus') partnersFocus: QueryList<any>;
  @ViewChildren('documentsFocus') documentsFocus: QueryList<any>;
  
  @ViewChild('partnerAutoComplete')
  private partnertAutoComplete: PartnerAutocompleteComponent;
  
  @ViewChild('firstInput')
  private firstInput: ElementRef<HTMLInputElement>;

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.firstInput.nativeElement.focus();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  constructor(
    private fb: FormBuilder,
    private productsService: ProductsService,    
    private router: Router,
    private route: ActivatedRoute,
    private toastService: NbToastrService,
    private config: ApiConfiguration,
  ) {

    const { url } = route;
    const version = this.config.rootUrl.slice(this.config.rootUrl.lastIndexOf("/"));
    const rootUrlWithoutVersion = this.config.rootUrl.replace(version, "");
    this.staticURL = `${rootUrlWithoutVersion}/uploads/products`;

    url.subscribe(segments => {
      if (segments.map(segment => segment.path).includes('new')) {
        this.crudType = 'new';
      } else if (segments.map(segment => segment.path).includes('edit')) {
        this.crudType = 'edit';
        this.route.params.subscribe(params => {
          this.id = params.id;
          this.loadDataFromServer(this.id);
        })
      }
    });    
  }

  successMsg(msg: string, icon: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' }) {
    this.toastService.show(msg, "Success!", { hasIcon: true, status: 'success', ...icon });
  }

  errorMsg(msg: string, icon: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' }) {
    this.toastService.show(msg, "Error!", { hasIcon: true, status: 'danger', ...icon })
  }


  /**
   * Links subform
   */
  links = new FormArray([]);

  addLink() {
    const linkForm = this.createLinkForm();
    this.links.push(linkForm);
    setTimeout(() => {
      this.linksFocus.last.nativeElement.focus();
    }, 300);    
  }

  removeLink(index: number) {    
    this.links.removeAt(index);    
  }

  createLinkForm() {
    return new FormGroup({
      name: new FormControl(''),
      url: new FormControl('', Validators.required),
    });
  }

  /**
   * Partner subform
   */
  partners = new FormArray([])

  partnerDefaults = {        
    partner_id: '',
    product_id: '',
    name: '',
    leadTime: 0,
    minimum: 0,
  }

  addPartner() {
    const partner = this.selectedPartner || {text: '', value: ''};
    const partnerForm = this.createPartnerForm();
    
    // set defaults
    partnerForm.reset({ ...this.partnerDefaults, partner_id: partner.value, name: partner.text });
    this.partners.push(partnerForm);
        
    const selectedPartners = [...this.selectedPartners$.value, partner.value];
    this.selectedPartners$.next(selectedPartners);
    this.partnertAutoComplete.cleanInput();
    this.selectedPartner = undefined;

    setTimeout(() => {
      this.partnersFocus.last.nativeElement.focus();
    }, 300);    
  }

  removePartner(index: number) {
    const selectedPartners =  this.selectedPartners$.value.filter(partnerId => partnerId !== this.partners.at(index).value.partner_id);    
    this.selectedPartners$.next(selectedPartners);    
    this.partners.removeAt(index);    
    this.partnertAutoComplete.refreshOptions();
  }

  createPartnerForm(): FormGroup {
    return this.fb.group({      
      partner_id: ['', Validators.required],
      product_id: [''],
      name: ['', Validators.required],
      leadTime: [0, Validators.min(0)],
      minimum: [0, Validators.min(0)],
    })
  }

  /**
   * Documents subform
   */
  documents = new FormArray([]);

  addDocument(name: string) {
    const documentForm = this.createDocumentForm(name);
    this.documents.push(documentForm);
    setTimeout(() => {
      this.documentsFocus.last?.nativeElement.focus();
    }, 300);    
  }

  removeDocument(index: number) {     
    this.documents.at(index).patchValue({deleted: true});
  }

  createDocumentForm(name) {
    return new FormGroup({
      originalName: new FormControl(name),
      description: new FormControl(''),
      meta: new FormControl(null),      
      deleted: new FormControl(false),
    });
  }


  /**
   * Main form
   */
  productForm = this.fb.group({
    name: [null, Validators.required],
    itemNumber: [null, Validators.required],
    barCode: [null],
    type: [null, Validators.required],    
    links: this.links,
    partners: this.partners,
    documents: this.documents,
    files: [[], Validators.required],
  })

  get name() { return this.productForm.get('name'); }
  get itemNumber() { return this.productForm.get('itemNumber'); }
  get type() { return this.productForm.get('type'); }

  loadDataFromServer(id) {
    this.productsService.productGet({ id }).subscribe(response => {      
      response.partners?.forEach(partner => {
        if (typeof partner === 'object') {
          const partnerForm = this.createPartnerForm();
          this.partners.push(partnerForm);
          
          const selectedPartners = [...this.selectedPartners$.value, partner.partner_id];
          this.selectedPartners$.next(selectedPartners);
        }        
      });            
            
      response.links?.forEach(link => {
        const linkForm = this.createLinkForm();
        this.links.push(linkForm);
      })

      response.documents?.forEach(document => {
        const documentForm = this.createDocumentForm((document as DocumentSchema).meta?.originalname || (document as DocumentSchema).originalName);
        this.documents.push(documentForm);
      })

      this.productForm.reset(response);
      
    }, (error) => {
      this.errorMsg(error.message);
    })
  }

  onSubmit() {
    if (this.crudType === 'new') {
      this.submitNew();
    } else {
      this.submitEdit();
    }
  }

  submitNew() {
    console.log(this.productForm.getRawValue());    
    this.productsService.productPost({ body: this.productForm.getRawValue() }).subscribe(response => {      
      this.successMsg('Record successfully created.');
      this.router.navigate(['products', 'list']);
    }, (error) => {
      this.errorMsg(error); 
    });
  }

  submitEdit() {
    console.log(this.productForm.getRawValue());    
    this.productsService.productPut({ id: this.id, body: this.productForm.value }).subscribe(response => {      
      this.successMsg('Record successfully modified.');
      this.router.navigate(['products', 'list']);
    }, (error) => {
      this.errorMsg(error);
    });
  }

  onPartnerChange(partner) {
    this.selectedPartner = partner;
  }

  trackByFn(index:any, item:any){
    return index;
  }  

  onFileAdd(event) {        
    for (var i = 0; i < event.target.files.length; i++) {
      const value = this.productForm.getRawValue();      
      const file = event.target.files[i];      
      this.productForm.patchValue({        
        files: [
          ...(value['files'] || []), 
          file,
        ]
      });
      this.addDocument(file.name);
    }
    console.log(this.productForm.getRawValue());
  }

  /*onFileChange(event, i: number) {
    let reader = new FileReader();    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      reader.onload = () => {
        this.documents.at(i).patchValue({          
          file: reader.result,
        });
      };

      reader.readAsDataURL(file);      
    }
  }*/

  log(val) { console.log(val); }
}
