/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProductBaseSchema } from '../models/product-base-schema';
import { ProductPartnersAsReference } from '../models/product-partners-as-reference';
import { ProductPartnersPupulated } from '../models/product-partners-pupulated';
import { ProductRequestBody } from '../models/product-request-body';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation productsGet
   */
  static readonly ProductsGetPath = '/products';

  /**
   * Get products list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<ProductBaseSchema & ProductPartnersAsReference>>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductBaseSchema & ProductPartnersAsReference>>;
      })
    );
  }

  /**
   * Get products list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsGet(params?: {

  }): Observable<Array<ProductBaseSchema & ProductPartnersAsReference>> {

    return this.productsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductBaseSchema & ProductPartnersAsReference>>) => r.body as Array<ProductBaseSchema & ProductPartnersAsReference>)
    );
  }

  /**
   * Path part for operation productPost
   */
  static readonly ProductPostPath = '/product';

  /**
   * Create a new product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  productPost$Response(params?: {
      body?: ProductRequestBody
  }): Observable<StrictHttpResponse<ProductBaseSchema & ProductPartnersPupulated>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductPostPath, 'post');
    if (params) {


      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductBaseSchema & ProductPartnersPupulated>;
      })
    );
  }

  /**
   * Create a new product
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  productPost(params?: {
      body?: ProductRequestBody
  }): Observable<ProductBaseSchema & ProductPartnersPupulated> {

    return this.productPost$Response(params).pipe(
      map((r: StrictHttpResponse<ProductBaseSchema & ProductPartnersPupulated>) => r.body as ProductBaseSchema & ProductPartnersPupulated)
    );
  }

  /**
   * Path part for operation productGet
   */
  static readonly ProductGetPath = '/product/{id}';

  /**
   * Read a product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGet$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<ProductBaseSchema & ProductPartnersPupulated>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductBaseSchema & ProductPartnersPupulated>;
      })
    );
  }

  /**
   * Read a product
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGet(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<ProductBaseSchema & ProductPartnersPupulated> {

    return this.productGet$Response(params).pipe(
      map((r: StrictHttpResponse<ProductBaseSchema & ProductPartnersPupulated>) => r.body as ProductBaseSchema & ProductPartnersPupulated)
    );
  }

  /**
   * Path part for operation productPut
   */
  static readonly ProductPutPath = '/product/{id}';

  /**
   * Modify a product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  productPut$Response(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: ProductRequestBody
  }): Observable<StrictHttpResponse<ProductBaseSchema>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductPutPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductBaseSchema>;
      })
    );
  }

  /**
   * Modify a product
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  productPut(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: ProductRequestBody
  }): Observable<ProductBaseSchema> {

    return this.productPut$Response(params).pipe(
      map((r: StrictHttpResponse<ProductBaseSchema>) => r.body as ProductBaseSchema)
    );
  }

  /**
   * Path part for operation productDelete
   */
  static readonly ProductDeletePath = '/product/{id}';

  /**
   * Delete a product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  productDelete$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete a product
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productDelete(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.productDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

}
