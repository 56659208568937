import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PartnerRequestBody } from 'app/openapi-hq/models';
import { PartnersService } from 'app/openapi-hq/services';
import readXlsxFile from 'read-excel-file';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ntgr-partner-import',
  templateUrl: './partner-import.component.html',
  styleUrls: ['./partner-import.component.scss']
})
export class PartnerImportComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private partnersService: PartnersService,
  ) { }

  ngOnInit() {
      const defaultColumns = ["name", "country", "zip", "city", "address", "taxNumber", "euTaxNumber"];
      defaultColumns.forEach(column => {
        this.addColumn(column);
      })
  }

  rows = new BehaviorSubject<PartnerRequestBody[]>([]);
  columns = new FormArray([]);
  columnList = [
    "name",
    "companyEmail",
    "country",
    "city",
    "zip",
    "address",
    "companyPhone",
    "taxNumber",
    "euTaxNumber"
  ]

  addColumn(value = "") {
    const columnsGroup = this.createColumnsGroup(value);
    this.columns.push(columnsGroup);
  }

  removeColumn(index: number) {
    this.columns.removeAt(index);
  }

  createColumnsGroup(value = "") {
    return this.fb.group({
      name: [`${value}`]
    })
  }


  importForm = new FormGroup({
    columns: this.columns,
    addAddress: new FormControl(false),
    file: new FormControl(''),
  });
  
  onFileAdd(event) {
    const file = event.target.files[0];
    readXlsxFile(file).then((rows) => {
      const mapper = this.importForm.controls["columns"].value.map(item => item.name);
      this.rows.next(rows.map(row => {
        return mapper.reduce((a, c, i) => {
          if (["address", "city", "zip", "country"].includes(c)) {
            a["companyAddresses"][0][c] = `${row[i]}`
          } else {
            a[c] = row[i];
          }
          return a;
        }, {
          companyAddresses: [{}],
        })
      }));
    })
  }

  submit() {    
    this.partnersService.partnersImport({body: this.rows.value, addaddress: this.importForm.value.addAddress}).subscribe(result => {
      console.log(result);
    })
  }
}
