/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiKey } from '../models/api-key';
import { ObjectId } from '../models/object-id';

@Injectable({
  providedIn: 'root',
})
export class ApikeysService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiKeysGet
   */
  static readonly ApiKeysGetPath = '/apikeys';

  /**
   * Get api keys list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiKeysGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiKeysGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<ApiKey>>> {

    const rb = new RequestBuilder(this.rootUrl, ApikeysService.ApiKeysGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApiKey>>;
      })
    );
  }

  /**
   * Get api keys list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiKeysGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiKeysGet(params?: {

  }): Observable<Array<ApiKey>> {

    return this.apiKeysGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ApiKey>>) => r.body as Array<ApiKey>)
    );
  }

  /**
   * Path part for operation apiKeyDelete
   */
  static readonly ApiKeyDeletePath = '/apikey/{id}';

  /**
   * Delete an api key
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiKeyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiKeyDelete$Response(params: {

    /**
     * Api key id
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ApikeysService.ApiKeyDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete an api key
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiKeyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiKeyDelete(params: {

    /**
     * Api key id
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.apiKeyDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation apiKeyPatch
   */
  static readonly ApiKeyPatchPath = '/apikey/{id}';

  /**
   * Modify a key object (currently u can modify only app name)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiKeyPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiKeyPatch$Response(params: {

    /**
     * Api key id
     */
    id: string;
      body: { 'app_name': string }
  }): Observable<StrictHttpResponse<ObjectId & ApiKey>> {

    const rb = new RequestBuilder(this.rootUrl, ApikeysService.ApiKeyPatchPath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectId & ApiKey>;
      })
    );
  }

  /**
   * Modify a key object (currently u can modify only app name)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiKeyPatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiKeyPatch(params: {

    /**
     * Api key id
     */
    id: string;
      body: { 'app_name': string }
  }): Observable<ObjectId & ApiKey> {

    return this.apiKeyPatch$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectId & ApiKey>) => r.body as ObjectId & ApiKey)
    );
  }

  /**
   * Path part for operation apiKeyPost
   */
  static readonly ApiKeyPostPath = '/apikey';

  /**
   * Get api keys list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiKeyPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiKeyPost$Response(params: {
      body: { 'app_name': string }
  }): Observable<StrictHttpResponse<{ 'key': string }>> {

    const rb = new RequestBuilder(this.rootUrl, ApikeysService.ApiKeyPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'key': string }>;
      })
    );
  }

  /**
   * Get api keys list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiKeyPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiKeyPost(params: {
      body: { 'app_name': string }
  }): Observable<{ 'key': string }> {

    return this.apiKeyPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'key': string }>) => r.body as { 'key': string })
    );
  }

}
