import { ChangeDetectionStrategy, Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ObjectId } from 'app/openapi-auth/models';
import { PartnerBaseSchema } from 'app/openapi-hq/models';
import { PartnersService } from 'app/openapi-hq/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

type Option = {text: string; value: string;};
type PartnerSchema = PartnerBaseSchema & ObjectId;


@Component({
  selector: 'partner-autocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './partner-autocomplete.component.html',
})
export class PartnerAutocompleteComponent implements OnInit {  
  public filteredOptions$: Observable<Option[]>;
  private partners: PartnerSchema[] = [];
  public options$ = new BehaviorSubject<Option[]>([]);
  
  @ViewChild('autoInput') input;

  @Input()
  excludePartners$ = new BehaviorSubject<string[]>([]);

  @Output()
  selectItem = new EventEmitter<Option>();

  constructor(private partnerService: PartnersService) {
    this.partnerService.partnersGet().subscribe(partners => {
      this.partners = partners;
      this.refreshOptions();
    })
  }

  ngOnInit() {

  }

  refreshOptions = () => {    
    const options = this.partners.filter(partner => !this.excludePartners$.value.includes(partner._id)).map(partner => ({text: partner.name, value: partner._id})); 
    this.options$.next(options);
    this.filteredOptions$ = this.options$;    
  }

  private filter(value: string): Option[] {
    const filterValue = value.toLowerCase();    
    return this.options$.value.filter(option => option['text'].toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<Option[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);    
  }

  onSelectionChange($event) {    
    this.filteredOptions$ = this.getFilteredOptions(this.options$.value.find(option => option.value === $event)['text']);    
    this.selectItem.emit(this.options$.value.find(option => option.value === $event));    
  }

  viewHandle = (value: string) => {
    const option = this.options$.value.find(option => option.value === value);
    return (option && option['text']) || value;
  }

  cleanInput = () => {
    this.input.nativeElement.value = '';    
    this.refreshOptions();
  }

}
