import { Injectable } from '@angular/core';
import { UserModel } from 'app/openapi-auth/models';
import { AuthService as ApiAuthService } from "app/openapi-auth/services";
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  // Access Token
  public access_token: string;
  private access_token_expiration: number;

  // Refresh timer in ms
  private refresh_timer: ReturnType<typeof setInterval>;

  // current User
  private currentUserSubject = new BehaviorSubject<UserModel>(null);;
  public currentUser: Observable<UserModel>;

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  private apiKey = "5e315e6379a3.fa.Ruvn.'{1[!8;B:<NG!~MfeJc'GsW-Rx9qm&~<";
  //private apiKey = 'ccc5087b1974._Oz@mKJc*^+U6R,_h[=swH<Ew1iD(o`l*eB:-0mM';
  
  /**
   * Creates an instance of AuthService.
   *
   * @param {ApiAuthService} apiAuthService
   * @memberof AuthService
   */
  constructor(private apiAuthService: ApiAuthService) {
      this.currentUser = this.currentUserSubject.asObservable();      
   }  

  /**
   *
   * Login the user
   *
   * @param email
   * @param password
   */
  login(email: string, password: string) {
    return this.apiAuthService.loginPost({body: {email, password}}).pipe(first(), map(({access_token}) => {
      this.updateAccessToken(access_token);
    }));
  }

  /**
   * Logout the current user
   *
   * @memberof AuthService
   */
  logout() {
    this.currentUserSubject.next(undefined);
    this.access_token_expiration = undefined;
    this.access_token = undefined;
    this.stopRefreshTimer();    
  }

  logoutFromApi() {
    return this.apiAuthService.logoutPost();
  }

  /**
   * Updating access_token and user informations when a new access_token received.
   *
   * @param access_token
   */
  updateAccessToken(access_token) {
    this.access_token = access_token;
    const parsedToken = this.parseJwt(access_token) as UserModel & {exp: number, lat: number};

    const parsedUser = Object.keys(parsedToken).filter(key => !['exp', 'iat'].includes(key)).reduce((a, c) => {
      a[c] = parsedToken[c];
      return a;
    }, {} as UserModel);

    this.currentUserSubject.next(parsedUser);

    const lifeTime = parsedToken['exp'] - parsedToken['iat'];
    this.access_token_expiration = lifeTime * 0.9 * 1000;
    this.resetRefreshTimer();
  }

  refreshToken() {
    return this.apiAuthService.tokenPost({
      "Cache-Control": "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      "Expires": "0",
      "Pragma": "no-cache",
    }).pipe(map(({access_token}) => {
      this.updateAccessToken(access_token);
      this.resetRefreshTimer();
    }, (error) => {
      this.logout();
    }));
  }

  /**
   * reset the refresh timer, and request a new token
   *
   * @memberof AuthService
   */
  resetRefreshTimer() {
    clearInterval(this.refresh_timer);
    if (!this.access_token_expiration) {
      this.refreshToken().subscribe();
    } else {
      this.refresh_timer = setTimeout(() => {
        this.refreshToken().subscribe()
      }, this.access_token_expiration);
    }
  }

  stopRefreshTimer() {
    clearInterval(this.refresh_timer);
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload);
  };

  getAPIKey() {
    return this.apiKey;
  }

}
