<nb-card class="users-list">
  <nb-card-header>
    Products List
    <button nbButton status="info" (click)="onCreate()"><nb-icon icon="plus-outline"></nb-icon>New</button>
  </nb-card-header>

  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="source" (deleteConfirm)="onDeleteConfirm($event)" (sort)="onSort($event)" (edit)="onEdit($event)" (create)="onCreate()" (delete)="onDelete($event)">
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
