/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LicensePackage } from '../models/license-package';

@Injectable({
  providedIn: 'root',
})
export class LicensepackagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation licensePackagesGet
   */
  static readonly LicensePackagesGetPath = '/licensepackages';

  /**
   * Get all licensepackage list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePackagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensePackagesGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<LicensePackage>>> {

    const rb = new RequestBuilder(this.rootUrl, LicensepackagesService.LicensePackagesGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LicensePackage>>;
      })
    );
  }

  /**
   * Get all licensepackage list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePackagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensePackagesGet(params?: {

  }): Observable<Array<LicensePackage>> {

    return this.licensePackagesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LicensePackage>>) => r.body as Array<LicensePackage>)
    );
  }

  /**
   * Path part for operation licensePackagePost
   */
  static readonly LicensePackagePostPath = '/licensepackage';

  /**
   * Create a new licensepackage
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePackagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePackagePost$Response(params?: {
      body?: LicensePackage & any
  }): Observable<StrictHttpResponse<LicensePackage>> {

    const rb = new RequestBuilder(this.rootUrl, LicensepackagesService.LicensePackagePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicensePackage>;
      })
    );
  }

  /**
   * Create a new licensepackage
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePackagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePackagePost(params?: {
      body?: LicensePackage & any
  }): Observable<LicensePackage> {

    return this.licensePackagePost$Response(params).pipe(
      map((r: StrictHttpResponse<LicensePackage>) => r.body as LicensePackage)
    );
  }

  /**
   * Path part for operation licensePackageGet
   */
  static readonly LicensePackageGetPath = '/licensepackage/{id}';

  /**
   * Read a licencepackage
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePackageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensePackageGet$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<LicensePackage>> {

    const rb = new RequestBuilder(this.rootUrl, LicensepackagesService.LicensePackageGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicensePackage>;
      })
    );
  }

  /**
   * Read a licencepackage
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePackageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensePackageGet(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<LicensePackage> {

    return this.licensePackageGet$Response(params).pipe(
      map((r: StrictHttpResponse<LicensePackage>) => r.body as LicensePackage)
    );
  }

  /**
   * Path part for operation licensePackagePut
   */
  static readonly LicensePackagePutPath = '/licensepackage/{id}';

  /**
   * Modify a licensepackage
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePackagePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePackagePut$Response(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: LicensePackage & any
  }): Observable<StrictHttpResponse<LicensePackage>> {

    const rb = new RequestBuilder(this.rootUrl, LicensepackagesService.LicensePackagePutPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicensePackage>;
      })
    );
  }

  /**
   * Modify a licensepackage
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePackagePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePackagePut(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: LicensePackage & any
  }): Observable<LicensePackage> {

    return this.licensePackagePut$Response(params).pipe(
      map((r: StrictHttpResponse<LicensePackage>) => r.body as LicensePackage)
    );
  }

  /**
   * Path part for operation licensePackageDelete
   */
  static readonly LicensePackageDeletePath = '/licensepackage/{id}';

  /**
   * Delete a licensepackage
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePackageDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensePackageDelete$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LicensepackagesService.LicensePackageDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete a licensepackage
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePackageDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensePackageDelete(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.licensePackageDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

}
