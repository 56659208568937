import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { PartnersService } from 'app/openapi-hq/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProductAutocompleteComponent } from '../product-autocomplete/product-autocomplete.component';


@Component({
  selector: 'ntgr-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit {

  public crudType: 'new' | 'edit';
  private id: string;
  private subscriptions:Subscription[] = [];
  public selectedProduct: {text: string; value: string;};
  public selectedProducts$ = new BehaviorSubject<string[]>([]);

  @ViewChildren('companyAddressesFocus') companyAddressesFocus: QueryList<any>;
  @ViewChildren('contactsFocus') contactsFocus: QueryList<any>;
  @ViewChildren('productsFocus') productsFocus: QueryList<any>;

  @ViewChild('firstInput')
  private firstInput: ElementRef<HTMLInputElement>;

  @ViewChild('productAutoComplete')
  private productAutoComplete: ProductAutocompleteComponent;

  defaultValues = {    
  }

  

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInput.nativeElement.focus();
  }

  constructor(private fb: FormBuilder, private partnersService: PartnersService, private router: Router, private route: ActivatedRoute, private toastService: NbToastrService) {
    const { url } = route;

    url.subscribe(segments => {      
      if (segments.map(segment => segment.path).includes('new')) {
        this.crudType = 'new';
      } else if (segments.map(segment => segment.path).includes('edit')) {
        this.crudType = 'edit';        
        this.route.params.subscribe(params => {          
          this.id = params.id;
          this.loadDataFromServer(this.id);
        })
      }
    })

  }

  successMsg(msg: string, icon: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' }) {
    this.toastService.show(msg, "Success!", { hasIcon: true, status: 'success', ...icon });
  }

  errorMsg(msg: string, icon: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' }) {
    this.toastService.show(msg, "Error!", { hasIcon: true, status: 'danger', ...icon })
  }

  minLengthArray = (min: number) => {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length >= min)
        return null;
  
      return { MinLengthArray: true};
    }
  }

  /**
   * companyAddresses subform
   */
  companyAddresses = new FormArray([], this.minLengthArray(1));

  addCompanyAddress() {
    const companyAddressForm = this.createCompanyAddressForm();
    this.companyAddresses.push(companyAddressForm);
    setTimeout(() => {
      this.companyAddressesFocus.last.nativeElement.focus();
    }, 300);
  }

  removeCompanyAddress(index: number) {    
    this.companyAddresses.removeAt(index);    
  }

  createCompanyAddressForm() {
    return this.fb.group({
      name: [null],
      country: ['Hungary', Validators.required],
      city: [null, Validators.required],
      zip: [null, Validators.required],
      address: [null, Validators.required],
    })
  }

  /**
   * Contacts subform
   */
  contacts = new FormArray([]);

  addContact() {
    const ContactForm = this.createContactForm();
    this.contacts.push(ContactForm);
    setTimeout(() => {
      this.contactsFocus.last.nativeElement.focus();
    }, 300);
  }

  removeContact(index: number) {    
    this.contacts.removeAt(index);    
  }

  createContactForm() {
    return this.fb.group({
      name: [],
      email: [],
      phone: [],
    })
  }

  /**
   * Products subform
   */
  products = new FormArray([]);

  addProduct() {
    const product = this.selectedProduct || {text: '', value: ''};
    const productForm = this.createProductForm();
    
    // set defaults
    productForm.reset({product_id: product.value, name: product.text});
    this.products.push(productForm);
        
    const selectedProducts = [...this.selectedProducts$.value, product.value];
    this.selectedProducts$.next(selectedProducts);
    this.productAutoComplete.cleanInput();
    this.selectedProduct = undefined;

    setTimeout(() => {
      this.productsFocus.last.nativeElement.focus();
    }, 300);    
  }

  removeProduct(index: number) {    
    const selectedProducts =  this.selectedProducts$.value.filter(productId => productId !== this.products.at(index).value.product_id);        
    this.selectedProducts$.next(selectedProducts);    
    this.products.removeAt(index);    
    this.productAutoComplete.refreshOptions();
    
  }

  createProductForm() {
    return this.fb.group({      
      partner_id: [''],
      product_id: ['', Validators.required],
      name: ['', Validators.required],
      leadTime: [0, Validators.min(0)],
      minimum: [0, Validators.min(0)],
    })
  }

  /**
   * Main form
   */
  partnerForm = this.fb.group({    
    name: [null, Validators.required],
    companyEmail: [null, [Validators.email]],
    companyAddresses: this.companyAddresses,
    companyPhone: [null],
    taxNumber: [null],
    euTaxNumber: [null],
    contacts: this.contacts,
    products: this.products,
  })

  get name() { return this.partnerForm.get('name'); }
  get companyEmail() { return this.partnerForm.get('companyEmail'); }  

  loadDataFromServer(id) {
    this.partnersService.partnerGet({ id }).subscribe(response => {            
      response.products?.forEach(product => {        
        const productForm = this.createProductForm();
        this.products.push(productForm);
        
        const selectedProducts = [...this.selectedProducts$.value, product.product_id];
        this.selectedProducts$.next(selectedProducts);        
      });            
            
      response.companyAddresses?.forEach(companyAddress => {
        const companyAddressForm = this.createCompanyAddressForm();
        this.companyAddresses.push(companyAddressForm);
      });

      response.contacts?.forEach(contact => {
        const contactForm = this.createContactForm();
        this.contacts.push(contactForm);
      })

      this.partnerForm.reset(response);
      
    }, (error) => {
      this.errorMsg(error.message);
    })
  }

  onSubmit() {
    if (this.crudType === 'new') {
      this.submitNew();
    } else {
      this.submitEdit();
    }
  }

  submitNew() {
    this.partnersService.partnerPost({ body: this.partnerForm.value }).subscribe(response => {      
      this.successMsg('Record successfully created.');
      this.router.navigate(['partners', 'list']);
    }, (error) => {
      this.errorMsg(error);
    })
  }

  submitEdit() {
    this.partnersService.partnerPut({id: this.id , body: this.partnerForm.value}).subscribe(response => {      
      this.successMsg('Record successfully modified.');
      this.router.navigate(['partners', 'list']);
    }, (error) => {
      this.errorMsg(error);
    })
  }

  onProductChange(product) {
    this.selectedProduct = product;
  }

  trackByFn(index:any, item:any){
    return index;
  }  

}
