<form [formGroup]="importForm">
    <div formArrayName="columns">
      <div class="row">
        <button type="button" nbButton (click)="addColumn()">Add Column</button>
      </div>
      <div class="row subform-row" *ngFor="let column of columns.controls; let i=index;">
        <ng-container [formGroup]="column">
          <div class="row">
            <div class="col-md-3">
              {{i+1}}
            </div>
            <div class="col-md-3">
              <select formControlName="name">
                <option *ngFor="let columnName of columnList" [value]="columnName">{{columnName}}</option>
              </select>
            </div>
          </div>          
          <div class="col-md-1">            
              <nb-icon (click)="removeColumn(i)" size="small" icon="close"></nb-icon>            
          </div>
        </ng-container>        
      </div>
    </div>
    <div class="row">
      <label for="add_address">
        Import as new address if already exists.
      </label>      
      <input type="checkbox" formControlName="addAddress" name="addAddress" id="add_address" />
    </div>
    <div class="row ">
      <input type="file" (change)="onFileAdd($event)">
      <button *ngIf="(rows | async).length" nbButton status="danger" (click)="submit()">Send to server</button>
    </div>        
</form>

<div>
  Number of records: {{(rows | async).length}}
  <div class="row" *ngFor="let row of rows | async">    
    <pre>      
      {{row | json}}      
    </pre>
  </div>
</div>
