import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxRenderer } from './components/CheckboxRenderer';
import { DateTimeRenderer } from './components/DateTimeRenderer';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAutocompleteModule, NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbThemeModule } from '@nebular/theme';
import { ThemeModule } from 'app/@theme/theme.module';
import { NtgrAutocompleteComponent } from './components/ntgr-autocomplete/ntgr-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,    
    NbMenuModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbThemeModule,        
    NbFormFieldModule,
    NbInputModule,
    NbSelectModule,
    FormsModule,
    ReactiveFormsModule,    
    NbAutocompleteModule,    
  ],
  exports: [
    AddressFormComponent,
    NtgrAutocompleteComponent,
  ],
  declarations: [
    CheckboxRenderer,
    DateTimeRenderer,
    AddressFormComponent,
    NtgrAutocompleteComponent
  ]
})
export class NtgrCommonModule { }
