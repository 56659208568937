import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnersComponent } from './partners.component';
import { PartnersRoutingModule } from './partners-routing.module';
import { ThemeModule } from 'app/@theme/theme.module';
import { NbAutocompleteModule, NbButtonModule, NbCardModule, NbFormFieldModule, NbIconComponent, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbThemeModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PartnerListComponent } from './components/partner-list/partner-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartnerFormComponent } from './components/partner-form/partner-form.component';
import { NtgrCommonModule } from '../ntgr-common/ntgr-common.module';
import { AddressFormComponent } from '../ntgr-common/components/address-form/address-form.component';
import { ProductsModule } from '../products/products.module';
import { ProductAutocompleteComponent } from './components/product-autocomplete/product-autocomplete.component';
import { PartnerImportComponent } from './components/partner-import/partner-import.component';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    NbMenuModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbThemeModule,
    Ng2SmartTableModule,
    PartnersRoutingModule,
    NbFormFieldModule,
    NbInputModule,
    NbSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NtgrCommonModule,
    NbAutocompleteModule,    
  ],  
  declarations: [
    PartnersComponent,
    PartnerListComponent,
    PartnerFormComponent,
    PartnerImportComponent,
    ProductAutocompleteComponent, 
  ]
})
export class PartnersModule { }
