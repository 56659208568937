import { Component, Input, OnInit } from '@angular/core';

import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <div *ngIf="renderValue"><i class="nb-checkmark"></i></div>
    <div *ngIf="!renderValue"><i class="nb-close"></i></div>
  `,
  styles: [
    `i { font-size: 1.8em }`,
    'i.nb-checkmark {color: green}',
    'i.nb-close {color: red}',
  ],
})
export class CheckboxRenderer implements ViewCell, OnInit {

  renderValue: boolean;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = !!this.value;
  }

}
