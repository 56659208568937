/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { License } from '../models/license';

@Injectable({
  providedIn: 'root',
})
export class LicensesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation licensesGet
   */
  static readonly LicensesGetPath = '/licenses';

  /**
   * Get all licenses list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensesGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<License>>> {

    const rb = new RequestBuilder(this.rootUrl, LicensesService.LicensesGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<License>>;
      })
    );
  }

  /**
   * Get all licenses list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licensesGet(params?: {

  }): Observable<Array<License>> {

    return this.licensesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<License>>) => r.body as Array<License>)
    );
  }

  /**
   * Path part for operation licensePost
   */
  static readonly LicensePostPath = '/license';

  /**
   * Create a new license
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePost$Response(params?: {
      body?: License & any
  }): Observable<StrictHttpResponse<License>> {

    const rb = new RequestBuilder(this.rootUrl, LicensesService.LicensePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<License>;
      })
    );
  }

  /**
   * Create a new license
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePost(params?: {
      body?: License & any
  }): Observable<License> {

    return this.licensePost$Response(params).pipe(
      map((r: StrictHttpResponse<License>) => r.body as License)
    );
  }

  /**
   * Path part for operation licenseGet
   */
  static readonly LicenseGetPath = '/license/{id}';

  /**
   * Read a licence
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licenseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  licenseGet$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<License>> {

    const rb = new RequestBuilder(this.rootUrl, LicensesService.LicenseGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<License>;
      })
    );
  }

  /**
   * Read a licence
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licenseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licenseGet(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<License> {

    return this.licenseGet$Response(params).pipe(
      map((r: StrictHttpResponse<License>) => r.body as License)
    );
  }

  /**
   * Path part for operation licensePut
   */
  static readonly LicensePutPath = '/license/{id}';

  /**
   * Modify a license
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licensePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePut$Response(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: License & any
  }): Observable<StrictHttpResponse<License>> {

    const rb = new RequestBuilder(this.rootUrl, LicensesService.LicensePutPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<License>;
      })
    );
  }

  /**
   * Modify a license
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licensePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  licensePut(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: License & any
  }): Observable<License> {

    return this.licensePut$Response(params).pipe(
      map((r: StrictHttpResponse<License>) => r.body as License)
    );
  }

  /**
   * Path part for operation licenseDelete
   */
  static readonly LicenseDeletePath = '/license/{id}';

  /**
   * Delete a license
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licenseDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  licenseDelete$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LicensesService.LicenseDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete a license
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licenseDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licenseDelete(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.licenseDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation licenseCheck
   */
  static readonly LicenseCheckPath = '/check-license';

  /**
   * Check license validity by license name
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `licenseCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  licenseCheck$Response(params: {

    /**
     * User id (ObjectId)
     */
    user_id: string;

    /**
     * Licnese id (ObjectId)
     */
    license_id: string;

    /**
     * If you only wanna check the validity, without increasing used counter
     */
    dry?: boolean;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, LicensesService.LicenseCheckPath, 'get');
    if (params) {

      rb.query('user_id', params.user_id, {});
      rb.query('license_id', params.license_id, {});
      rb.query('dry', params.dry, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Check license validity by license name
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `licenseCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  licenseCheck(params: {

    /**
     * User id (ObjectId)
     */
    user_id: string;

    /**
     * Licnese id (ObjectId)
     */
    license_id: string;

    /**
     * If you only wanna check the validity, without increasing used counter
     */
    dry?: boolean;

  }): Observable<boolean> {

    return this.licenseCheck$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
