import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ProductListComponent } from "./components/product-list/product-list.component";
import { ProductsComponent } from './products.component';
import { NotFoundComponent } from 'app/pages/miscellaneous/not-found/not-found.component';
import { ProductFormComponent } from './components/product-form/product-form.component'; 

export const routes: Routes = [{
  path: '',
  component: ProductsComponent,
  children: [
    {
      path: 'list',
      component: ProductListComponent,
    },
    {
      path: 'edit/:id',
      component: ProductFormComponent,
    },
    {
      path: 'new',
      component: ProductFormComponent,
    },
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {
}

export const routedComponents = [ProductListComponent, ProductFormComponent];
