import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { AuthService, UsersService } from 'app/openapi-auth/services';
import { PartnersService } from 'app/openapi-hq/services';

import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'ntgr-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent {

  private compareDate = (value) => value ? new Date(value).getTime() : 0;

  settings = {
    mode: 'external',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },    
    actions: {
      add: true,
      edit: true,
      delete: true,      
    },
    columns: {
      name: {
        title: 'Name',
        type: 'string',
      },
      companyEmail: {
        title: 'Company email',
        type: 'string',
      }
    },
    pager: {
      perPage: 50,
    }
  };

  source = new LocalDataSource();
  private removedIcon: NbIconConfig = { icon: 'person-remove-outline', pack: 'eva' };
  private modifiedIcon: NbIconConfig = { icon: 'person-done-outline', pack: 'eva' };

  constructor(private partnersService: PartnersService, private toastService: NbToastrService, private router: Router) {
    this.loadDataFromServer();    
  }

  loadDataFromServer() {
    this.partnersService.partnersGet().subscribe((partners) => {
      this.source.load(partners).then(() => {
        
      });
    });

  }

  successMsg(msg: string, icon: NbIconConfig) {
    this.toastService.show(msg, "Success!", {hasIcon: true, status: 'success', ...icon});
  }

  errorMsg(msg: string, icon: NbIconConfig) {
    this.toastService.show(msg, "Error!", {hasIcon: true, status: 'danger', ...icon})
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete record?')) {
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  onSort(event) {
    console.log(event);
  }

  onCreate() {
    this.router.navigate(['partners', "new"]);
  }

  onEdit(event) {
    const {data} = event;
    this.router.navigate(['partners', "edit", data._id]);
  }

  onDelete(event) {
    const {data} = event;
    this.partnersService.partnerDelete({id: data._id}).subscribe(response => {
      if (response.message === "ok") {
        this.successMsg("Record successfully deleted", {icon: "checkmark-outline", pack: 'eva'});
        this.loadDataFromServer();
      }
    })
  }

  onImport() {
    this.router.navigate(['partners', "import"]);
  }

}
