import { ChangeDetectionStrategy, Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

type Option = {text: string; value: string;};
type Item = {_id: string } & { [key: string]: any};

@Component({
  selector: 'ntgr-autocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ntgr-autocomplete.component.html',
})
export class NtgrAutocompleteComponent implements OnInit {  
  public filteredOptions$: Observable<Option[]>;  
  public options$ = new BehaviorSubject<Option[]>([]);
  
  @ViewChild('autoInput') input;

  @Input()
  itemList: Item[] = [];

  @Input()
  placeholder: string;

  @Input()
  displayedKey: string = "name";

  @Input()
  excludeItems: string[] = [];

  @Input() 
  inputModel: string;
  
  @Output() inputModelChange = new EventEmitter<string>();

  @Output()
  selectItem = new EventEmitter<Option>();

  constructor() {
    
  }

  ngOnInit() {
    this.refreshOptionsWithExcluded();
  }
  
  ngOnChanges() {
    this.refreshOptionsWithExcluded();
  }

  refreshOptionsWithExcluded = () => {        
    const options = this.itemList.filter(item => !this.excludeItems.includes(item._id)).map(item => ({text: item[this.displayedKey], value: item._id}));    
    this.options$.next(options);
    this.filteredOptions$ = this.options$;    
  }

  private filter(value: string): Option[] {
    const filterValue = value.toLowerCase();    
    return this.options$.value.filter(option => option['text'].toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<Option[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);    
  }

  onSelectionChange($event) {    
    const options = this.options$.value.find(option => option.value === $event);
    this.filteredOptions$ = this.getFilteredOptions((options && options['text']) || '');    
    this.selectItem.emit(this.options$.value.find(option => option.value === $event));    
  }

  viewHandle = (value: string) => {
    const option = this.options$.value.find(option => option.value === value);
    return (option && option['text']) || value;
  }

  cleanInput = () => {
    this.input.nativeElement.value = '';    
    this.refreshOptionsWithExcluded();
  }

}
