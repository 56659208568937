/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MovementSchema } from '../models/movement-schema';
import { ReceivedNoteImportRequestBody } from '../models/received-note-import-request-body';
import { ReceivedNoteRequestBody } from '../models/received-note-request-body';

@Injectable({
  providedIn: 'root',
})
export class ReceivedNotesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation receivedNotesGet
   */
  static readonly ReceivedNotesGetPath = '/received-notes';

  /**
   * Get items list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedNotesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedNotesGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<MovementSchema>>> {

    const rb = new RequestBuilder(this.rootUrl, ReceivedNotesService.ReceivedNotesGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MovementSchema>>;
      })
    );
  }

  /**
   * Get items list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receivedNotesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedNotesGet(params?: {

  }): Observable<Array<MovementSchema>> {

    return this.receivedNotesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MovementSchema>>) => r.body as Array<MovementSchema>)
    );
  }

  /**
   * Path part for operation receivedNotePost
   */
  static readonly ReceivedNotePostPath = '/received-note';

  /**
   * Create a new received note
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedNotePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedNotePost$Response(params?: {
      body?: ReceivedNoteRequestBody
  }): Observable<StrictHttpResponse<MovementSchema>> {

    const rb = new RequestBuilder(this.rootUrl, ReceivedNotesService.ReceivedNotePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MovementSchema>;
      })
    );
  }

  /**
   * Create a new received note
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receivedNotePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedNotePost(params?: {
      body?: ReceivedNoteRequestBody
  }): Observable<MovementSchema> {

    return this.receivedNotePost$Response(params).pipe(
      map((r: StrictHttpResponse<MovementSchema>) => r.body as MovementSchema)
    );
  }

  /**
   * Path part for operation receivedNoteGet
   */
  static readonly ReceivedNoteGetPath = '/received-note/{id}';

  /**
   * Read an item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedNoteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedNoteGet$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<MovementSchema>> {

    const rb = new RequestBuilder(this.rootUrl, ReceivedNotesService.ReceivedNoteGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MovementSchema>;
      })
    );
  }

  /**
   * Read an item
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receivedNoteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedNoteGet(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<MovementSchema> {

    return this.receivedNoteGet$Response(params).pipe(
      map((r: StrictHttpResponse<MovementSchema>) => r.body as MovementSchema)
    );
  }

  /**
   * Path part for operation receivedNotePut
   */
  static readonly ReceivedNotePutPath = '/received-note/{id}';

  /**
   * Modify a store
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedNotePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedNotePut$Response(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: ReceivedNoteRequestBody
  }): Observable<StrictHttpResponse<MovementSchema>> {

    const rb = new RequestBuilder(this.rootUrl, ReceivedNotesService.ReceivedNotePutPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MovementSchema>;
      })
    );
  }

  /**
   * Modify a store
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receivedNotePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedNotePut(params: {

    /**
     * ObjectId
     */
    id: string;
      body?: ReceivedNoteRequestBody
  }): Observable<MovementSchema> {

    return this.receivedNotePut$Response(params).pipe(
      map((r: StrictHttpResponse<MovementSchema>) => r.body as MovementSchema)
    );
  }

  /**
   * Path part for operation receivedNoteDelete
   */
  static readonly ReceivedNoteDeletePath = '/received-note/{id}';

  /**
   * Delete an item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedNoteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedNoteDelete$Response(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ReceivedNotesService.ReceivedNoteDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete an item
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receivedNoteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedNoteDelete(params: {

    /**
     * ObjectId
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.receivedNoteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation receivedNotesImport
   */
  static readonly ReceivedNotesImportPath = '/received-notes/import';

  /**
   * Bulk received note import
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedNotesImport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedNotesImport$Response(params?: {
      body?: Array<ReceivedNoteImportRequestBody>
  }): Observable<StrictHttpResponse<{ 'message'?: string, 'insertedCount'?: number }>> {

    const rb = new RequestBuilder(this.rootUrl, ReceivedNotesService.ReceivedNotesImportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string, 'insertedCount'?: number }>;
      })
    );
  }

  /**
   * Bulk received note import
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receivedNotesImport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedNotesImport(params?: {
      body?: Array<ReceivedNoteImportRequestBody>
  }): Observable<{ 'message'?: string, 'insertedCount'?: number }> {

    return this.receivedNotesImport$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string, 'insertedCount'?: number }>) => r.body as { 'message'?: string, 'insertedCount'?: number })
    );
  }

}
