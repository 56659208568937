/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Log } from '../models/log';

@Injectable({
  providedIn: 'root',
})
export class LogService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation logsGet
   */
  static readonly LogsGetPath = '/logs';

  /**
   * Get logs list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  logsGet$Response(params?: {
    pagesize?: number;
    page?: number;

  }): Observable<StrictHttpResponse<{ 'maxCount'?: number, 'data'?: Array<Log> }>> {

    const rb = new RequestBuilder(this.rootUrl, LogService.LogsGetPath, 'get');
    if (params) {

      rb.query('pagesize', params.pagesize, {});
      rb.query('page', params.page, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'maxCount'?: number, 'data'?: Array<Log> }>;
      })
    );
  }

  /**
   * Get logs list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logsGet(params?: {
    pagesize?: number;
    page?: number;

  }): Observable<{ 'maxCount'?: number, 'data'?: Array<Log> }> {

    return this.logsGet$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'maxCount'?: number, 'data'?: Array<Log> }>) => r.body as { 'maxCount'?: number, 'data'?: Array<Log> })
    );
  }

  /**
   * Path part for operation permaLogsGet
   */
  static readonly PermaLogsGetPath = '/permalogs';

  /**
   * Get long live logs list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permaLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  permaLogsGet$Response(params?: {
    pageSize?: number;
    page?: number;

  }): Observable<StrictHttpResponse<{ 'maxCount'?: number, 'data'?: Array<Log> }>> {

    const rb = new RequestBuilder(this.rootUrl, LogService.PermaLogsGetPath, 'get');
    if (params) {

      rb.query('pageSize', params.pageSize, {});
      rb.query('page', params.page, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'maxCount'?: number, 'data'?: Array<Log> }>;
      })
    );
  }

  /**
   * Get long live logs list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permaLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permaLogsGet(params?: {
    pageSize?: number;
    page?: number;

  }): Observable<{ 'maxCount'?: number, 'data'?: Array<Log> }> {

    return this.permaLogsGet$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'maxCount'?: number, 'data'?: Array<Log> }>) => r.body as { 'maxCount'?: number, 'data'?: Array<Log> })
    );
  }

}
