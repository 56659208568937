import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Apps',
    icon: 'browser-outline',
    children: [
      {
        title: 'API Keys',
        link: '/apps/api-keys',
      }
    ]
  },
  {
    title: 'Master data',
    icon: 'browser-outline',
    children: [
      {
        title: 'Stores',
        link: '/stores/list',
      },
      {
        title: 'Products',
        link: '/products/list',
      },
      {
        title: 'Partners',
        link: '/partners/list',
      }
    ]
  },
  {
    title: "Movements",
    icon: 'swap-outline',
    children: [
      {
        title: 'Received Notes',
        link: '/received-notes/list'
      }
    ]
  },
  {
    title: 'Users',
    icon: 'people-outline',
    children: [
      {
        title: 'Users CRUD',
        link: '/users/list',
      },
      {
        title: 'Licenses',
        link: '/licenses/list',
      },
      {
        title: 'License packages',
        link: '/license-packages/list',
      }
    ]
  },
  {
    title: 'Logs',
    icon: 'file-text-outline',
    children: [
      {
        title: 'Auth Request Logs',
        link: '/logs/auth',
      },
      {
        title: 'Auth Perma Logs',
        link: '/logs/auth/perma',
      }
    ]
  },
  {
    title: 'Auth',
    icon: 'lock-outline',
    children: [
      {
        title: 'Login',
        link: '/auth/login',
      },
      /*{
        title: 'Register',
        link: '/auth/register',
      },
      {
        title: 'Request Password',
        link: '/auth/request-password',
      },
      {
        title: 'Reset Password',
        link: '/auth/reset-password',
      },*/
    ],
  },
];
