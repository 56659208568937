import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { observable, Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authService.currentUserValue;
      if (currentUser) {
        return true;
      } else {
          return new Observable<boolean>((observer) => {
            this.authService.refreshToken().subscribe(() => {
              observer.next(true);
            }, () => {
              observer.next(false);
              this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
            })
          })
      }
    }
}
