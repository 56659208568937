/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserModel } from '../models/user-model';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation approvePatch
   */
  static readonly ApprovePatchPath = '/approve/{id}';

  /**
   * Set isValidEmail property true for the user represented with the given id parameter. Authorization header must contain access_token of user with isAdmin=true credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approvePatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  approvePatch$Response(params: {

    /**
     * MongoDb ObjectId for the user in string format
     */
    id: string;

  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApprovePatchPath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Set isValidEmail property true for the user represented with the given id parameter. Authorization header must contain access_token of user with isAdmin=true credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approvePatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approvePatch(params: {

    /**
     * MongoDb ObjectId for the user in string format
     */
    id: string;

  }): Observable<UserModel> {

    return this.approvePatch$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

  /**
   * Path part for operation approveByHash
   */
  static readonly ApproveByHashPath = '/approve-by-hash/{hash}';

  /**
   * Set isValidEmail property true for the user represented by the given hash parameter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveByHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveByHash$Response(params: {

    /**
     * secure approval hash for a specific user
     */
    hash: string;

  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApproveByHashPath, 'get');
    if (params) {

      rb.path('hash', params.hash, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Set isValidEmail property true for the user represented by the given hash parameter.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approveByHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveByHash(params: {

    /**
     * secure approval hash for a specific user
     */
    hash: string;

  }): Observable<UserModel> {

    return this.approveByHash$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

  /**
   * Path part for operation usersGet
   */
  static readonly UsersGetPath = '/users';

  /**
   * Get registered users list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet$Response(params?: {

  }): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * Get registered users list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet(params?: {

  }): Observable<Array<UserModel>> {

    return this.usersGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * Path part for operation userGet
   */
  static readonly UserGetPath = '/user/{id}';

  /**
   * Get a user object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Response(params: {

    /**
     * User id
     */
    id: string;

  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UserGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Get a user object
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet(params: {

    /**
     * User id
     */
    id: string;

  }): Observable<UserModel> {

    return this.userGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

  /**
   * Path part for operation userDelete
   */
  static readonly UserDeletePath = '/user/{id}';

  /**
   * Delete a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDelete$Response(params: {

    /**
     * User id
     */
    id: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UserDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete a user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDelete(params: {

    /**
     * User id
     */
    id: string;

  }): Observable<{ 'message'?: string }> {

    return this.userDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation userPatch
   */
  static readonly UserPatchPath = '/user/{id}';

  /**
   * Modify a user object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPatch$Response(params: {

    /**
     * User id
     */
    id: string;
      body: UserModel
  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UserPatchPath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Modify a user object
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPatch(params: {

    /**
     * User id
     */
    id: string;
      body: UserModel
  }): Observable<UserModel> {

    return this.userPatch$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

}
