import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from "app/modules/auth/helpers/auth.guard";


export const routes: Routes = [
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.NtgrUsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'logs',
    loadChildren: () => import('./modules/logs/logs.module').then(m => m.NtgrLogsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.NtgrAuthModule),
  },
  {
    path: 'apps',
    loadChildren: () => import('./modules/ntgr-apps/ntgr-apps.module').then(m => m.NtgrAppsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stores',
    loadChildren: () => import('./modules/stores/stores.module').then(m => m.StoresModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'partners',
    loadChildren: () => import('./modules/partners/partners.module').then(m => m.PartnersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'licenses',
    loadChildren: () => import('./modules/licenses/licenses.module').then(m => m.LicensesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'license-packages',
    loadChildren: () => import('./modules/license-packages/license-packages.module').then(m => m.LicensePackagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'received-notes',
    loadChildren: () => import('./modules/received-notes/received-notes.module').then(m => m.ReceivedNotesModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'users', pathMatch: 'full' },
  { path: '**', redirectTo: 'users' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
