import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ThemeModule } from 'app/@theme/theme.module';
import { NbAutocompleteModule, NbButtonModule, NbCardModule, NbFormFieldModule, NbIconComponent, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbThemeModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ProductListComponent } from './components/product-list/product-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { NtgrCommonModule } from '../ntgr-common/ntgr-common.module';
import { PartnersModule } from '../partners/partners.module';
import { PartnerAutocompleteComponent } from './components/partner-autocomplete/partner-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,    
    NbMenuModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbThemeModule,
    Ng2SmartTableModule,
    ProductsRoutingModule,
    NbFormFieldModule,
    NbInputModule,
    NbSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NtgrCommonModule,
    NbAutocompleteModule,    
  ],  
  declarations: [
    ProductsComponent,
    ProductListComponent,
    ProductFormComponent,  
    PartnerAutocompleteComponent,  
  ]
})
export class ProductsModule { }
