<div class="row">
  <div class="col-md-12">
    <nb-card class="form-card">
      <nb-card-header>
        <span *ngIf="crudType === 'new'">New product</span>
        <span *ngIf="crudType === 'edit'">Edit "{{productForm.getRawValue().name}}"</span>
      </nb-card-header>
      <nb-card-body>
        <form class="form" [formGroup]="productForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
          <nb-card>
            <nb-card-header>
              <h2>Core data</h2>
            </nb-card-header>
            <nb-card-body>
              <div class="row">
                <div class="col-md-12">
                  <label for="name">
                    <div>Name<span class="mandatory">*</span></div>
                    <input #firstInput type="text" nbInput fullWidth placeholder="Product name" formControlName="name"
                      name="name" required>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                      <div *ngIf="name.errors.required">
                        Name is required.
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="itemNumber">
                    <div>Itemnumber<span class="mandatory">*</span></div>
                    <input type="text" nbInput fullWidth placeholder="ItemNumber" formControlName="itemNumber"
                      name="itemNumber" required>
                    <div *ngIf="itemNumber.invalid && (itemNumber.dirty || itemNumber.touched)" class="text-danger">
                      <div *ngIf="itemNumber.errors.required">
                        ItemNumber is required.
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-md-4">
                  <label for="barCode">
                    Barcode
                    <input type="text" nbInput fullWidth placeholder="Barcode" formControlName="barCode"
                      name="barCode" required>                    
                  </label>
                </div>
                <div class="col-md-4">
                  <label for="type">
                    <div>Type<span class="mandatory">*</span></div>
                    <nb-select fullWidth placeholder="Select type" formControlName="type" name="type" required>
                      <nb-option value="commodity">Commodity</nb-option>
                      <nb-option value="semi-finished">Semi-finished</nb-option>
                      <nb-option value="finished">Finished</nb-option>
                    </nb-select>
                    <div *ngIf="type.invalid && (type.dirty || type.touched)" class="text-danger">
                      <div *ngIf="type.errors.required">
                        Type is required.
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </nb-card-body>
          </nb-card>

          <nb-card formArrayName="links" class="links">
            <nb-card-header>
              <div class="row">
                <div class="col-xl-10 col-md-9 col-sm-12">
                  <h2>Links:</h2>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                  <button type="button" nbButton (click)="addLink()">Add Link</button>
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div class="row subform-row" *ngFor="let link of links.controls; let i=index;  trackBy:trackByFn">
                <ng-container [formGroup]="link">
                  <div class="col-md-5">
                    <label>
                      Name
                      <input nbInput #linksFocus type="text" fullWidth formControlName="name" />
                    </label>
                  </div>
                  <div class="col-md-5">
                    <label>
                      <div class="link-value">
                        <span>URL</span>
                        <a *ngIf="links.value[i]" [href]="links.value[i].url || ''"
                          target="_blank">{{links.value[i].name}}</a>
                      </div>
                      <input nbInput type="text" fullWidth formControlName="url" />
                    </label>
                  </div>
                  <div class="col-md-2">
                    <button type="button" nbButton status="danger" (click)="removeLink(i)">
                      <nb-icon icon="close"></nb-icon>
                    </button>
                  </div>                  
                </ng-container>
              </div>
            </nb-card-body>
          </nb-card>

          <nb-card formArrayName="partners">
            <nb-card-header>
              <div class="row withField">
                <div class="col-xl-10 col-md-9 col-sm-12">
                  <h2>Partners</h2>
                  <partner-autocomplete #partnerAutoComplete (selectItem)="onPartnerChange($event)"
                    [excludePartners$]="selectedPartners$"></partner-autocomplete>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                  <button [disabled]="!selectedPartner" type="button" nbButton fullWidth (click)="addPartner()"
                    (keydown.enter)="addPartner()">Add partner</button>
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div class="row subform-row" *ngFor="let partner of partners.controls; let i=index">
                <ng-container [formGroup]="partner">
                  <div class="col-md-4">
                    <label>
                      Partner name
                      <input nbInput type="text" readonly fullWidth formControlName="name">
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label>
                      Lead time (days)
                      <input #partnersFocus nbInput type="number" fullWidth formControlName="leadTime">
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label>
                      Minimum (pieces)
                      <input nbInput type="number" fullWidth formControlName="minimum">
                    </label>
                  </div>
                  <div class="col-md-2">
                    <button type="button" nbButton status="danger" (click)="removePartner(i, partner.value.id)">
                      <nb-icon icon="close"></nb-icon>
                    </button>
                  </div>
                  <input type="hidden" formControlName="product_id">
                  <input type="hidden" formControlName="partner_id">                  
                </ng-container>
              </div>
            </nb-card-body>
          </nb-card>

          <nb-card formArrayName="documents" class="documents subform">
            <nb-card-header>
              <div class="row">
                <div class="col-xl-10 col-md-9 col-sm-12">
                  <h2>Documents</h2>
                  <input nbInput type="file" fullWidth multiple (change)="onFileAdd($event)"
                    name="file" />
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div class="row subform-row" *ngFor="let document of documents.controls; let i=index;  trackBy:trackByFn">
                <ng-container *ngIf="!document.value.deleted" [formGroup]="document">
                  <div class="col-md-4">
                    <label>
                      File name                      
                      <div *ngIf="document.value.meta">
                        <a href="{{staticURL}}/{{document.value.meta.filename}}" target="_blank">{{document.value.originalName}}</a>
                      </div>
                      <div *ngIf="!document.value.meta">
                        {{document.value.originalName}}
                      </div>
                    </label>
                  </div>
                  <div class="col-md-6">
                    <label>
                      Leírás
                      <textarea nbInput #linksFocus type="text" fullWidth formControlName="description"></textarea>
                    </label>
                  </div>
                  <input type="hidden" formControlName="deleted" />
                  <input type="hidden" formControlName="meta" />
                  <div class="col-md-2">
                    <button type="button" nbButton status="danger" (click)="removeDocument(i)">
                      <nb-icon icon="close"></nb-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </nb-card-body>
          </nb-card>

          <div>
            <button type="submit" nbButton status="primary" [disabled]="false">Save</button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>