/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Email } from '../models/email';
import { Password } from '../models/password';
import { Token } from '../models/token';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation loginPost
   */
  static readonly LoginPostPath = '/login';

  /**
   * Check user credentials and provide an access_token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginPost$Response(params: {
      body: { 'email': Email, 'password': Password }
  }): Observable<StrictHttpResponse<{ 'access_token'?: Token }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'access_token'?: Token }>;
      })
    );
  }

  /**
   * Check user credentials and provide an access_token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loginPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginPost(params: {
      body: { 'email': Email, 'password': Password }
  }): Observable<{ 'access_token'?: Token }> {

    return this.loginPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'access_token'?: Token }>) => r.body as { 'access_token'?: Token })
    );
  }

  /**
   * Path part for operation logoutPost
   */
  static readonly LogoutPostPath = '/logout';

  /**
   * Delete user's refresh_token from database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoutPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoutPost$Response(params?: {

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LogoutPostPath, 'post');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete user's refresh_token from database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoutPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoutPost(params?: {

  }): Observable<{ 'message'?: string }> {

    return this.logoutPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation registerPost
   */
  static readonly RegisterPostPath = '/register';

  /**
   * Register a new user to our database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerPost$Response(params: {
      body: { 'email': Email, 'password': Password, 'repeat_password': Password }
  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.RegisterPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Register a new user to our database
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerPost(params: {
      body: { 'email': Email, 'password': Password, 'repeat_password': Password }
  }): Observable<{ 'message'?: string }> {

    return this.registerPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation tokenPost
   */
  static readonly TokenPostPath = '/token';

  /**
   * Request a new access_token with a valid refresh_token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tokenPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  tokenPost$Response(params?: {
    'Cache-Control'?: string;
    Pragma?: string;
    Expires?: string;

  }): Observable<StrictHttpResponse<{ 'access_token'?: Token }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.TokenPostPath, 'post');
    if (params) {

      rb.header('Cache-Control', params['Cache-Control'], {});
      rb.header('Pragma', params.Pragma, {});
      rb.header('Expires', params.Expires, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'access_token'?: Token }>;
      })
    );
  }

  /**
   * Request a new access_token with a valid refresh_token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tokenPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tokenPost(params?: {
    'Cache-Control'?: string;
    Pragma?: string;
    Expires?: string;

  }): Observable<{ 'access_token'?: Token }> {

    return this.tokenPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'access_token'?: Token }>) => r.body as { 'access_token'?: Token })
    );
  }

  /**
   * Path part for operation forgotPassPost
   */
  static readonly ForgotPassPostPath = '/forgot-password';

  /**
   * Request an email to the given email address to change user's password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassPost$Response(params: {
      body: { 'email': Email }
  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ForgotPassPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Request an email to the given email address to change user's password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPassPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassPost(params: {
      body: { 'email': Email }
  }): Observable<{ 'message'?: string }> {

    return this.forgotPassPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation forgotPasswordCheckGet
   */
  static readonly ForgotPasswordCheckGetPath = '/forgot-password/check/{hash}';

  /**
   * Check the hash validity for a forgot password request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPasswordCheckGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  forgotPasswordCheckGet$Response(params: {

    /**
     * Hash string
     */
    hash: string;

  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ForgotPasswordCheckGetPath, 'get');
    if (params) {

      rb.path('hash', params.hash, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Check the hash validity for a forgot password request
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPasswordCheckGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  forgotPasswordCheckGet(params: {

    /**
     * Hash string
     */
    hash: string;

  }): Observable<{ 'message'?: string }> {

    return this.forgotPasswordCheckGet$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation changePasswdPost
   */
  static readonly ChangePasswdPostPath = '/change-password';

  /**
   * Change user's password by the given one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePasswdPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePasswdPost$Response(params: {
      body: { 'hash': string, 'password': Password }
  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ChangePasswdPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Change user's password by the given one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePasswdPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePasswdPost(params: {
      body: { 'hash': string, 'password': Password }
  }): Observable<{ 'message'?: string }> {

    return this.changePasswdPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

}
