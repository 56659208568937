<div class="row">
  <div class="col-md-12">
    <nb-card class="form-card">
      <nb-card-header>
        <span *ngIf="crudType === 'new'">New partner</span>
        <span *ngIf="crudType === 'edit'">Edit "{{partnerForm.getRawValue().name}}"</span>
      </nb-card-header>
      <nb-card-body>
        <form class="form" [formGroup]="partnerForm" (ngSubmit)="onSubmit()">
          <nb-card>
            <nb-card-header>
              <h2>Core data</h2>
            </nb-card-header>
            <nb-card-body>
              <div class="row">
                <div class="col-md-6">
                  <label>
                    <div>Name<span class="mandatory">*</span></div>
                    <input type="text" #firstInput nbInput fullWidth placeholder="Partner name" formControlName="name"
                      required [status]="name.invalid && (name.dirty || name.touched) ? 'danger': 'basic'">
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                      <div *ngIf="name.errors.required">
                        Name is required.
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-md-6">
                  <label>
                    <div>Company email</div>
                    <input type="text" nbInput fullWidth placeholder="Company email" formControlName="companyEmail"                      
                      [status]="companyEmail.invalid && (companyEmail.dirty || companyEmail.touched) ? 'danger': 'basic'">
                    <div *ngIf="companyEmail.invalid && (companyEmail.dirty || companyEmail.touched)"
                      class="text-danger">
                      <div *ngIf="companyEmail.errors.required">
                        Company e-mail is required.
                      </div>
                      <div *ngIf="companyEmail.errors.email">
                        Company e-mail must be a vaild email address.
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <label>
                    Phone
                    <input type="text" nbInput fullWidth placeholder="Phone" formControlName="companyPhone">
                  </label>
                </div>
                <div class="col-md-4">
                  <label>
                    Tax number
                    <input type="text" nbInput fullWidth placeholder="Tax number" formControlName="taxNumber">
                  </label>
                </div>
                <div class="col-md-4">
                  <label>
                    Tax number (EU)
                    <input type="text" nbInput fullWidth placeholder="Tax number" formControlName="taxNumber">
                  </label>
                </div>
              </div>
            </nb-card-body>
          </nb-card>

          <nb-card formArrayName="companyAddresses" class="addresses subform">
            <nb-card-header>
              <div class="row">
                <div class="col-xl-10 col-md-9 col-sm-12">
                  <h2>Addresses<span class="mandatory">*</span>:</h2>
                  <div *ngIf="companyAddresses.invalid" class="text-danger">
                    Please add at least one address to the partner!
                  </div>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                  <button type="button" nbButton (click)="addCompanyAddress()">Add Address</button>
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div class="row subform-row"
                *ngFor="let companyAddress of companyAddresses.controls; let i=index;  trackBy:trackByFn">
                <ng-container [formGroup]="companyAddress">
                  <div class="col-md-2">
                    <label for="Name">
                      Name
                      <input #companyAddressesFocus name="Name" type="text" nbInput fullWidth formControlName="name">
                      <div
                        *ngIf="companyAddress.get('name').invalid && (companyAddress.get('name').dirty || companyAddress.get('name').touched)"
                        class="text-danger">
                        <div *ngIf="companyAddress.get('name').errors.required">
                          Address name is required.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-md-2">
                    <label for="Country">
                      Country
                      <nb-select name="Country" fullWidth placeholder="" formControlName="country">
                        <nb-option value="" selected="selected">Select Country</nb-option>
                        <nb-option value="United States">United States</nb-option>
                        <nb-option value="United Kingdom">United Kingdom</nb-option>
                        <nb-option value="Afghanistan">Afghanistan</nb-option>
                        <nb-option value="Albania">Albania</nb-option>
                        <nb-option value="Algeria">Algeria</nb-option>
                        <nb-option value="American Samoa">American Samoa</nb-option>
                        <nb-option value="Andorra">Andorra</nb-option>
                        <nb-option value="Angola">Angola</nb-option>
                        <nb-option value="Anguilla">Anguilla</nb-option>
                        <nb-option value="Antarctica">Antarctica</nb-option>
                        <nb-option value="Antigua and Barbuda">Antigua and Barbuda</nb-option>
                        <nb-option value="Argentina">Argentina</nb-option>
                        <nb-option value="Armenia">Armenia</nb-option>
                        <nb-option value="Aruba">Aruba</nb-option>
                        <nb-option value="Australia">Australia</nb-option>
                        <nb-option value="Austria">Austria</nb-option>
                        <nb-option value="Azerbaijan">Azerbaijan</nb-option>
                        <nb-option value="Bahamas">Bahamas</nb-option>
                        <nb-option value="Bahrain">Bahrain</nb-option>
                        <nb-option value="Bangladesh">Bangladesh</nb-option>
                        <nb-option value="Barbados">Barbados</nb-option>
                        <nb-option value="Belarus">Belarus</nb-option>
                        <nb-option value="Belgium">Belgium</nb-option>
                        <nb-option value="Belize">Belize</nb-option>
                        <nb-option value="Benin">Benin</nb-option>
                        <nb-option value="Bermuda">Bermuda</nb-option>
                        <nb-option value="Bhutan">Bhutan</nb-option>
                        <nb-option value="Bolivia">Bolivia</nb-option>
                        <nb-option value="Bosnia and Herzegovina">Bosnia and Herzegovina</nb-option>
                        <nb-option value="Botswana">Botswana</nb-option>
                        <nb-option value="Bouvet Island">Bouvet Island</nb-option>
                        <nb-option value="Brazil">Brazil</nb-option>
                        <nb-option value="British Indian Ocean Territory">British Indian Ocean Territory</nb-option>
                        <nb-option value="Brunei Darussalam">Brunei Darussalam</nb-option>
                        <nb-option value="Bulgaria">Bulgaria</nb-option>
                        <nb-option value="Burkina Faso">Burkina Faso</nb-option>
                        <nb-option value="Burundi">Burundi</nb-option>
                        <nb-option value="Cambodia">Cambodia</nb-option>
                        <nb-option value="Cameroon">Cameroon</nb-option>
                        <nb-option value="Canada">Canada</nb-option>
                        <nb-option value="Cape Verde">Cape Verde</nb-option>
                        <nb-option value="Cayman Islands">Cayman Islands</nb-option>
                        <nb-option value="Central African Republic">Central African Republic</nb-option>
                        <nb-option value="Chad">Chad</nb-option>
                        <nb-option value="Chile">Chile</nb-option>
                        <nb-option value="China">China</nb-option>
                        <nb-option value="Christmas Island">Christmas Island</nb-option>
                        <nb-option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</nb-option>
                        <nb-option value="Colombia">Colombia</nb-option>
                        <nb-option value="Comoros">Comoros</nb-option>
                        <nb-option value="Congo">Congo</nb-option>
                        <nb-option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The
                        </nb-option>
                        <nb-option value="Cook Islands">Cook Islands</nb-option>
                        <nb-option value="Costa Rica">Costa Rica</nb-option>
                        <nb-option value="Cote D'ivoire">Cote D'ivoire</nb-option>
                        <nb-option value="Croatia">Croatia</nb-option>
                        <nb-option value="Cuba">Cuba</nb-option>
                        <nb-option value="Cyprus">Cyprus</nb-option>
                        <nb-option value="Czech Republic">Czech Republic</nb-option>
                        <nb-option value="Denmark">Denmark</nb-option>
                        <nb-option value="Djibouti">Djibouti</nb-option>
                        <nb-option value="Dominica">Dominica</nb-option>
                        <nb-option value="Dominican Republic">Dominican Republic</nb-option>
                        <nb-option value="Ecuador">Ecuador</nb-option>
                        <nb-option value="Egypt">Egypt</nb-option>
                        <nb-option value="El Salvador">El Salvador</nb-option>
                        <nb-option value="Equatorial Guinea">Equatorial Guinea</nb-option>
                        <nb-option value="Eritrea">Eritrea</nb-option>
                        <nb-option value="Estonia">Estonia</nb-option>
                        <nb-option value="Ethiopia">Ethiopia</nb-option>
                        <nb-option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</nb-option>
                        <nb-option value="Faroe Islands">Faroe Islands</nb-option>
                        <nb-option value="Fiji">Fiji</nb-option>
                        <nb-option value="Finland">Finland</nb-option>
                        <nb-option value="France">France</nb-option>
                        <nb-option value="French Guiana">French Guiana</nb-option>
                        <nb-option value="French Polynesia">French Polynesia</nb-option>
                        <nb-option value="French Southern Territories">French Southern Territories</nb-option>
                        <nb-option value="Gabon">Gabon</nb-option>
                        <nb-option value="Gambia">Gambia</nb-option>
                        <nb-option value="Georgia">Georgia</nb-option>
                        <nb-option value="Germany">Germany</nb-option>
                        <nb-option value="Ghana">Ghana</nb-option>
                        <nb-option value="Gibraltar">Gibraltar</nb-option>
                        <nb-option value="Greece">Greece</nb-option>
                        <nb-option value="Greenland">Greenland</nb-option>
                        <nb-option value="Grenada">Grenada</nb-option>
                        <nb-option value="Guadeloupe">Guadeloupe</nb-option>
                        <nb-option value="Guam">Guam</nb-option>
                        <nb-option value="Guatemala">Guatemala</nb-option>
                        <nb-option value="Guinea">Guinea</nb-option>
                        <nb-option value="Guinea-bissau">Guinea-bissau</nb-option>
                        <nb-option value="Guyana">Guyana</nb-option>
                        <nb-option value="Haiti">Haiti</nb-option>
                        <nb-option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands
                        </nb-option>
                        <nb-option value="Holy See (Vatican City State)">Holy See (Vatican City State)</nb-option>
                        <nb-option value="Honduras">Honduras</nb-option>
                        <nb-option value="Hong Kong">Hong Kong</nb-option>
                        <nb-option value="Hungary">Hungary</nb-option>
                        <nb-option value="Iceland">Iceland</nb-option>
                        <nb-option value="India">India</nb-option>
                        <nb-option value="Indonesia">Indonesia</nb-option>
                        <nb-option value="Iran, Islamic Republic of">Iran, Islamic Republic of</nb-option>
                        <nb-option value="Iraq">Iraq</nb-option>
                        <nb-option value="Ireland">Ireland</nb-option>
                        <nb-option value="Israel">Israel</nb-option>
                        <nb-option value="Italy">Italy</nb-option>
                        <nb-option value="Jamaica">Jamaica</nb-option>
                        <nb-option value="Japan">Japan</nb-option>
                        <nb-option value="Jordan">Jordan</nb-option>
                        <nb-option value="Kazakhstan">Kazakhstan</nb-option>
                        <nb-option value="Kenya">Kenya</nb-option>
                        <nb-option value="Kiribati">Kiribati</nb-option>
                        <nb-option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of
                        </nb-option>
                        <nb-option value="Korea, Republic of">Korea, Republic of</nb-option>
                        <nb-option value="Kuwait">Kuwait</nb-option>
                        <nb-option value="Kyrgyzstan">Kyrgyzstan</nb-option>
                        <nb-option value="Lao People's Democratic Republic">Lao People's Democratic Republic</nb-option>
                        <nb-option value="Latvia">Latvia</nb-option>
                        <nb-option value="Lebanon">Lebanon</nb-option>
                        <nb-option value="Lesotho">Lesotho</nb-option>
                        <nb-option value="Liberia">Liberia</nb-option>
                        <nb-option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</nb-option>
                        <nb-option value="Liechtenstein">Liechtenstein</nb-option>
                        <nb-option value="Lithuania">Lithuania</nb-option>
                        <nb-option value="Luxembourg">Luxembourg</nb-option>
                        <nb-option value="Macao">Macao</nb-option>
                        <nb-option value="North Macedonia">North Macedonia</nb-option>
                        <nb-option value="Madagascar">Madagascar</nb-option>
                        <nb-option value="Malawi">Malawi</nb-option>
                        <nb-option value="Malaysia">Malaysia</nb-option>
                        <nb-option value="Maldives">Maldives</nb-option>
                        <nb-option value="Mali">Mali</nb-option>
                        <nb-option value="Malta">Malta</nb-option>
                        <nb-option value="Marshall Islands">Marshall Islands</nb-option>
                        <nb-option value="Martinique">Martinique</nb-option>
                        <nb-option value="Mauritania">Mauritania</nb-option>
                        <nb-option value="Mauritius">Mauritius</nb-option>
                        <nb-option value="Mayotte">Mayotte</nb-option>
                        <nb-option value="Mexico">Mexico</nb-option>
                        <nb-option value="Micronesia, Federated States of">Micronesia, Federated States of</nb-option>
                        <nb-option value="Moldova, Republic of">Moldova, Republic of</nb-option>
                        <nb-option value="Monaco">Monaco</nb-option>
                        <nb-option value="Mongolia">Mongolia</nb-option>
                        <nb-option value="Montserrat">Montserrat</nb-option>
                        <nb-option value="Morocco">Morocco</nb-option>
                        <nb-option value="Mozambique">Mozambique</nb-option>
                        <nb-option value="Myanmar">Myanmar</nb-option>
                        <nb-option value="Namibia">Namibia</nb-option>
                        <nb-option value="Nauru">Nauru</nb-option>
                        <nb-option value="Nepal">Nepal</nb-option>
                        <nb-option value="Netherlands">Netherlands</nb-option>
                        <nb-option value="Netherlands Antilles">Netherlands Antilles</nb-option>
                        <nb-option value="New Caledonia">New Caledonia</nb-option>
                        <nb-option value="New Zealand">New Zealand</nb-option>
                        <nb-option value="Nicaragua">Nicaragua</nb-option>
                        <nb-option value="Niger">Niger</nb-option>
                        <nb-option value="Nigeria">Nigeria</nb-option>
                        <nb-option value="Niue">Niue</nb-option>
                        <nb-option value="Norfolk Island">Norfolk Island</nb-option>
                        <nb-option value="Northern Mariana Islands">Northern Mariana Islands</nb-option>
                        <nb-option value="Norway">Norway</nb-option>
                        <nb-option value="Oman">Oman</nb-option>
                        <nb-option value="Pakistan">Pakistan</nb-option>
                        <nb-option value="Palau">Palau</nb-option>
                        <nb-option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</nb-option>
                        <nb-option value="Panama">Panama</nb-option>
                        <nb-option value="Papua New Guinea">Papua New Guinea</nb-option>
                        <nb-option value="Paraguay">Paraguay</nb-option>
                        <nb-option value="Peru">Peru</nb-option>
                        <nb-option value="Philippines">Philippines</nb-option>
                        <nb-option value="Pitcairn">Pitcairn</nb-option>
                        <nb-option value="Poland">Poland</nb-option>
                        <nb-option value="Portugal">Portugal</nb-option>
                        <nb-option value="Puerto Rico">Puerto Rico</nb-option>
                        <nb-option value="Qatar">Qatar</nb-option>
                        <nb-option value="Reunion">Reunion</nb-option>
                        <nb-option value="Romania">Romania</nb-option>
                        <nb-option value="Russian Federation">Russian Federation</nb-option>
                        <nb-option value="Rwanda">Rwanda</nb-option>
                        <nb-option value="Saint Helena">Saint Helena</nb-option>
                        <nb-option value="Saint Kitts and Nevis">Saint Kitts and Nevis</nb-option>
                        <nb-option value="Saint Lucia">Saint Lucia</nb-option>
                        <nb-option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</nb-option>
                        <nb-option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</nb-option>
                        <nb-option value="Samoa">Samoa</nb-option>
                        <nb-option value="San Marino">San Marino</nb-option>
                        <nb-option value="Sao Tome and Principe">Sao Tome and Principe</nb-option>
                        <nb-option value="Saudi Arabia">Saudi Arabia</nb-option>
                        <nb-option value="Senegal">Senegal</nb-option>
                        <nb-option value="Serbia and Montenegro">Serbia and Montenegro</nb-option>
                        <nb-option value="Seychelles">Seychelles</nb-option>
                        <nb-option value="Sierra Leone">Sierra Leone</nb-option>
                        <nb-option value="Singapore">Singapore</nb-option>
                        <nb-option value="Slovakia">Slovakia</nb-option>
                        <nb-option value="Slovenia">Slovenia</nb-option>
                        <nb-option value="Solomon Islands">Solomon Islands</nb-option>
                        <nb-option value="Somalia">Somalia</nb-option>
                        <nb-option value="South Africa">South Africa</nb-option>
                        <nb-option value="South Georgia and The South Sandwich Islands">South Georgia and The South
                          Sandwich
                          Islands
                        </nb-option>
                        <nb-option value="Spain">Spain</nb-option>
                        <nb-option value="Sri Lanka">Sri Lanka</nb-option>
                        <nb-option value="Sudan">Sudan</nb-option>
                        <nb-option value="Suriname">Suriname</nb-option>
                        <nb-option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</nb-option>
                        <nb-option value="Swaziland">Swaziland</nb-option>
                        <nb-option value="Sweden">Sweden</nb-option>
                        <nb-option value="Switzerland">Switzerland</nb-option>
                        <nb-option value="Syrian Arab Republic">Syrian Arab Republic</nb-option>
                        <nb-option value="Taiwan, Province of China">Taiwan, Province of China</nb-option>
                        <nb-option value="Tajikistan">Tajikistan</nb-option>
                        <nb-option value="Tanzania, United Republic of">Tanzania, United Republic of</nb-option>
                        <nb-option value="Thailand">Thailand</nb-option>
                        <nb-option value="Timor-leste">Timor-leste</nb-option>
                        <nb-option value="Togo">Togo</nb-option>
                        <nb-option value="Tokelau">Tokelau</nb-option>
                        <nb-option value="Tonga">Tonga</nb-option>
                        <nb-option value="Trinidad and Tobago">Trinidad and Tobago</nb-option>
                        <nb-option value="Tunisia">Tunisia</nb-option>
                        <nb-option value="Turkey">Turkey</nb-option>
                        <nb-option value="Turkmenistan">Turkmenistan</nb-option>
                        <nb-option value="Turks and Caicos Islands">Turks and Caicos Islands</nb-option>
                        <nb-option value="Tuvalu">Tuvalu</nb-option>
                        <nb-option value="Uganda">Uganda</nb-option>
                        <nb-option value="Ukraine">Ukraine</nb-option>
                        <nb-option value="United Arab Emirates">United Arab Emirates</nb-option>
                        <nb-option value="United Kingdom">United Kingdom</nb-option>
                        <nb-option value="United States">United States</nb-option>
                        <nb-option value="United States Minor Outlying Islands">United States Minor Outlying Islands
                        </nb-option>
                        <nb-option value="Uruguay">Uruguay</nb-option>
                        <nb-option value="Uzbekistan">Uzbekistan</nb-option>
                        <nb-option value="Vanuatu">Vanuatu</nb-option>
                        <nb-option value="Venezuela">Venezuela</nb-option>
                        <nb-option value="Viet Nam">Viet Nam</nb-option>
                        <nb-option value="Virgin Islands, British">Virgin Islands, British</nb-option>
                        <nb-option value="Virgin Islands, U.S.">Virgin Islands, U.S.</nb-option>
                        <nb-option value="Wallis and Futuna">Wallis and Futuna</nb-option>
                        <nb-option value="Western Sahara">Western Sahara</nb-option>
                        <nb-option value="Yemen">Yemen</nb-option>
                        <nb-option value="Zambia">Zambia</nb-option>
                        <nb-option value="Zimbabwe">Zimbabwe</nb-option>
                      </nb-select>
                      <div
                        *ngIf="companyAddress.get('country').invalid && (companyAddress.get('country').dirty || companyAddress.get('country').touched)"
                        class="text-danger">
                        <div *ngIf="companyAddress.get('country').errors.required">
                          Country is required.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-md-2">
                    <label for="City">
                      City
                      <input name="City" type="text" nbInput fullWidth formControlName="city">
                      <div
                        *ngIf="companyAddress.get('city').invalid && (companyAddress.get('city').dirty || companyAddress.get('city').touched)"
                        class="text-danger">
                        <div *ngIf="companyAddress.get('city').errors.required">
                          City is required.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-md-1">
                    <label for="Zip">
                      Zip
                      <input name="Zip" type="text" nbInput fullWidth formControlName="zip">
                      <div
                        *ngIf="companyAddress.get('zip').invalid && (companyAddress.get('zip').dirty || companyAddress.get('zip').touched)"
                        class="text-danger">
                        <div *ngIf="companyAddress.get('zip').errors.required">
                          Zip is required.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label for="Address">
                      Address
                      <textarea name="Address" type="text" nbInput fullWidth formControlName="address"></textarea>
                      <div
                        *ngIf="companyAddress.get('address').invalid && (companyAddress.get('address').dirty || companyAddress.get('address').touched)"
                        class="text-danger">
                        <div *ngIf="companyAddress.get('address').errors.required">
                          Address is required.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-md-1">
                    <button type="button" nbButton status="danger" (click)="removeCompanyAddress(i)">
                      <nb-icon icon="close"></nb-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </nb-card-body>
          </nb-card>

          <nb-card formArrayName="contacts" class="contacts subform">
            <nb-card-header>
              <div class="row">
                <div class="col-xl-10 col-md-9 col-sm-12">
                  <h2>Contacts</h2>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                  <button type="button" nbButton (click)="addContact()">Add Contact</button>
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div class="row subform-row" *ngFor="let contact of contacts.controls; let i=index;  trackBy:trackByFn">
                <ng-container [formGroup]="contact">
                  <div class="col-md-4">
                    <label for="Name">
                      Name
                      <input #contactsFocus name="Name" type="text" nbInput fullWidth formControlName="name">
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label for="E-mail">
                      E-mail
                      <input name="E-mail" type="text" nbInput fullWidth formControlName="email">
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label for="Phone">
                      Phone
                      <input name="Phone" type="text" nbInput fullWidth formControlName="phone">
                    </label>
                  </div>
                  <div class="col-md-1">
                    <button type="button" nbButton status="danger" (click)="removeContact(i)">
                      <nb-icon icon="close"></nb-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </nb-card-body>
          </nb-card>

          <nb-card formArrayName="products" class="products subform">
            <nb-card-header>
              <div class="row withField">
                <div class="col-xl-10 col-md-9 col-sm-12">
                  <h2>Products</h2>
                  <product-autocomplete #productAutoComplete (selectItem)="onProductChange($event)"
                    [excludeProducts$]="selectedProducts$"></product-autocomplete>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                  <button [disabled]="!selectedProduct" type="button" nbButton (click)="addProduct()"
                    (keydown.enter)="addProduct()">Add product</button>
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
              <div class="row subform-row" *ngFor="let product of products.controls; let i=index">
                <ng-container [formGroup]="product">
                  <div class="col-md-4">
                    <label>
                      Product name
                      <input nbInput type="text" readonly fullWidth formControlName="name">
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label>
                      Lead time (days)
                      <input #productsFocus nbInput type="number" fullWidth formControlName="leadTime">
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label>
                      Minimum (pieces)
                      <input nbInput type="number" fullWidth formControlName="minimum">
                    </label>
                  </div>
                  <div class="col-md-2">
                    <button type="button" nbButton status="danger" (click)="removeProduct(i, product.value.id)">
                      <nb-icon icon="close"></nb-icon>
                    </button>
                  </div>                  
                  <input type="hidden" formControlName="product_id">
                  <input type="hidden" formControlName="partner_id">
                </ng-container>
              </div>
            </nb-card-body>
          </nb-card>

          <div>
            <button type="submit" nbButton status="primary" [disabled]="!partnerForm.valid">Save</button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-6">

  </div>
</div>