import { Component, Input, OnInit } from '@angular/core';

import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <ng-container *ngIf="value">{{year}}-{{month}}-{{day}} {{hour}}:{{min}}</ng-container>
    <ng-container *ngIf="!value">N/A</ng-container>
  `,
  styles: [

  ],
})
export class DateTimeRenderer implements ViewCell, OnInit {

  year: string;
  month: string;
  day: string;

  hour: string;
  min: string;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
    if (this.value) {
      const date = new Date(this.value);
      this.year = `${date.getFullYear()}`;
      this.month = this.prependNumber(date.getMonth()+1);
      this.day = this.prependNumber(date.getDate());

      this.hour = this.prependNumber(date.getHours());
      this.min = this.prependNumber(date.getMinutes());
    }
  }

  prependNumber(num: number, prep = '0') {
    return num < 10 ? `0${num}`: `${num}`;
  }

}
