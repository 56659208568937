<input #autoInput
  nbInput
  fullWidth  
  type="text"
  (input)="onChange()"
  placeholder="Search for product"
  [nbAutocomplete]="auto"
  [disabled]="(options$ | async).length === 0 ? true: false" />

<nb-autocomplete #auto (selectedChange)="onSelectionChange($event)" [handleDisplayFn]="viewHandle">

  <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">
    {{ option.text }}
  </nb-option>

</nb-autocomplete>

