import { ChangeDetectionStrategy, Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ObjectId } from 'app/openapi-auth/models';
import { ProductBaseSchema } from 'app/openapi-hq/models';
import { ProductsService } from 'app/openapi-hq/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

type Option = {text: string; value: string;};
type ProductSchema = ProductBaseSchema & ObjectId;


@Component({
  selector: 'product-autocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './product-autocomplete.component.html',
})
export class ProductAutocompleteComponent implements OnInit {  
  public filteredOptions$: Observable<Option[]>;
  private products: ProductSchema[] = [];
  public options$ = new BehaviorSubject<Option[]>([]);
  
  @ViewChild('autoInput') input;

  @Input()
  excludeProducts$ = new BehaviorSubject<string[]>([]);

  @Output()
  selectItem = new EventEmitter<Option>();  

  constructor(private productService: ProductsService) {
    this.productService.productsGet().subscribe(products => {
      this.products = products;
      this.refreshOptions();
    })
  }

  ngOnInit() {

  }  

  refreshOptions = () => {
    console.log(this.excludeProducts$.value);
    const options = this.products.filter(product => !this.excludeProducts$.value.includes(product._id)).map(product => ({text: product.name, value: product._id})); 
    this.options$.next(options);
    this.filteredOptions$ = this.options$;    
  }

  private filter(value: string): Option[] {
    const filterValue = value.toLowerCase();    
    return this.options$.value.filter(option => option['text'].toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<Option[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);    
  }

  onSelectionChange($event) {    
    this.filteredOptions$ = this.getFilteredOptions(this.options$.value.find(option => option.value === $event)['text']);    
    this.selectItem.emit(this.options$.value.find(option => option.value === $event));    
  }

  viewHandle = (value: string) => {
    const option = this.options$.value.find(option => option.value === value);
    return (option && option['text']) || value;
  }

  cleanInput = () => {
    this.input.nativeElement.value = '';    
    this.refreshOptions();
  }

}
